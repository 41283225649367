/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet";

interface SEOProps {
  title: string
  robots: string
}

const SEO: React.FC<SEOProps> = ({title, robots}) => {
  return (
      <Helmet
          htmlAttributes={{
            lang: 'de-DE',
            dir: 'ltr',
            prefix: 'og: http://ogp.me/ns#',
          }}
          title={title}
          titleTemplate={`%s`}
          meta={[
            {
              name: 'author',
              content: 'Tommy Seus, Halle, Germany',
            },
            {
              name: 'copyright',
              content: 'Tommy Seus, Halle, Germany',
            },
            {
              name: 'description',
              content: 'Tommy Seus - Vita',
            },
            {
              name: 'keywords',
              content: 'Tommy, Seus, Lebenslauf, Vita, PHP, Entwickler, Deutschland',
            },
            {
              name: 'twitter:site',
              content: '@tommyseus',
            },
            {
              name: 'referrer',
              content: 'never',
            },
            {
              name: 'robots',
              content: robots,
            },
            {
              name: 'og:title',
              content: 'Tommy Seus · Vita',
            },
            {
              name: 'og:type',
              content: 'website',
            },
            {
              name: 'og:url',
              content: '/',
            },
            {
              name: 'og:image',
              content: 'https://www.tommy-seus.de/asset/vita/img/tileImage.png',
            },
            {
              name: '',
              content: '',
            },
            {
              name: '',
              content: '',
            },
            {
              name: '',
              content: '',
            },
          ]}
      />
  )
}

SEO.defaultProps = {
  title: `Tommy Seus · Vita`,
  robots: `nosnippet,follow`,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  robots: PropTypes.string.isRequired,
}

export default SEO
